import {
  NewBrandsPageData,
  LandingPageData,
  LandingPageHeroType,
  LandingPageQuote,
  BrandVideoData,
} from '@/shared/types/landing-page';
import { SEO_META_PAGES } from '../types/seo';
import { STATIC_FILE_URL } from '@/shared/constants';

export const EXISTING_BRAND_PAGE_DATA: NewBrandsPageData = {
  hero: {
    title: 'Scale your brand & save up to 50% with Pietra',
    subtitle:
      "Pietra's members-only products and services gives brands competitive pricing on core software and services.",
    titleColor: '#fff',
    bgColor: 'radial-gradient(126.01% 147.85% at 4.11% 0%, #FF5C3C 0%, #F2C296 100%)',
    preTitleImg: '/svg/pietra-oval-logo.svg',
    btnText: 'Create Pietra account',
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/b01570be1f32c7020186e6dd44e0941b.png`,
        title: 'Easier Payments',
        description:
          'Put all of your invoices in one place and get a 0% service fee when you connect your existing suppliers.',
      },
      {
        image: `${STATIC_FILE_URL}/7a2d77cf1f30532a865aad2b7b34b7be.png`,
        title: 'Low Cost Fulfillment',
        description:
          'Get free shipping from your factory to a Pietra Fulfillment Center and get pick and pack fees starting at $1.15/order.',
      },
      {
        image: `${STATIC_FILE_URL}/a26b83630e3784a2e1617f86b4285b6e.png`,
        title: 'Easier Asset Management',
        description: 'Stop sending PDFs over email. Manage all of your production assets in one place.',
      },
    ],
  },
  highlightCards: {
    title: 'Ways to use Pietra',
    cards: [
      {
        img: `${STATIC_FILE_URL}/251fe554a8803390dba13e876f2bd60e.png`,
        title: 'Launch new product lines',
      },
      {
        img: `${STATIC_FILE_URL}/7746a93b29421c7efbfbe1003e765e13.png`,
        title: 'Streamline your fulfillment',
      },
      {
        img: `${STATIC_FILE_URL}/77d3276014c4e4e129cc59c7f06b35c2.png`,
        title: 'Scale & manage your operations',
      },
    ],
  },
  sectionsTitle: 'For the smartest E-commerce operators',
  sections: [
    {
      id: 'e-commerce',
      image: `${STATIC_FILE_URL}/78e60a083702fa85b5ab48300900d26c.png`,
      title: 'Centralize your communications & invoices.',
      description: null,
      buttonText: 'Learn more',
    },
    {
      id: 'sourcing',
      image: `${STATIC_FILE_URL}/42c7173913f8e96e26b589e80dcac5ca.png`,
      title: 'Expand your product offering without expanding your team.',
      description:
        'Source from trusted, vetted and trained manufacturers, to take the headache out of production. Work with the top suppliers that have worked with Nike, Gucci, Starbucks, and more to expand your product and avoid handling all the logistics yourself.',
      buttonText: 'Learn more',
    },
    {
      id: 'fulfillment',
      image: `${STATIC_FILE_URL}/7ec727838c9a4bcff9b70791a57f17c2.png`,
      title: '$1.15 order fulfillment & 2-Day shipping for all.',
      description:
        "Use Pietra's Fulfillment Center to ship orders to customers around the world. Optimized for product drops and growing e-commerce businesses.",
      buttonText: 'Learn more',
    },
    {
      id: 'education',
      image: `${STATIC_FILE_URL}/f1fc99ccd67681d83b1c975dd89b4bc8.png`,
      title: 'Get access to experts when you need them.',
      description:
        'Pietra allows you to book 1:1 time with production specialists, fulfillment wizards, and e-commerce professionals to help you scale up your business. Never feel like you have to figure it all out yourself. We got you.',
      buttonText: 'Learn more',
    },
  ],
};

export const NEW_BRAND_PAGE_DATA: NewBrandsPageData = {
  hero: {
    title: 'Start the brand of your dreams with Pietra.',
    subtitle: 'Pietra provides the software and services for you to start your brand quickly.',
    bgColor: 'radial-gradient(126.01% 147.85% at 4.11% 0%, #FF5C3C 0%, #F2C296 100%)',
    titleColor: '#fff',
    preTitleImg: '/svg/pietra-oval-logo.svg',
    btnText: 'Start your business now',
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/66da1b2875f9b968e7bc656b9944ccf7.png`,
        title: 'Manufacturing network',
        description: 'Work with 1200+ vetted suppliers to produce your product line and custom packaging.',
      },
      {
        image: `${STATIC_FILE_URL}/f709c7e5e9aa15cc7540b75d6f5c4e0a.png`,
        title: 'Fulfillment center',
        description:
          'Store your inventory and ship products to your customer using a members-only 3PL. Build for small, fast growing brands.',
      },
      {
        image: `${STATIC_FILE_URL}/697618d9980d86c639edae6d778eea38.png`,
        title: 'E-commerce software',
        description: 'Set up a storefront and access email.',
      },
    ],
  },
  highlightCards: {
    title: 'You have an idea, now you need the tools.',
    cards: [
      {
        img: '/svg/money-orange.svg',
        title: 'Find and work with factories.',
      },
      {
        img: '/svg/light-bulb-orange.svg',
        title: 'Send everything to a Pietra warehouse.',
      },
      {
        img: '/svg/process-orange.svg',
        title: 'Set up a storefront & start selling.',
      },
    ],
  },
  sectionsTitle: 'Trusted by over 250,000 entrepreneurs',
  sections: [
    {
      id: 'sourcing',
      image: `${STATIC_FILE_URL}/01b4530f6ae219c98522a719f678c227.png`,
      title: 'Planning tools for beginners.',
      description:
        'Use our step-by-step guide to understand how to take your idea and turn it into a thriving business.',
      buttonText: 'Start your business now',
    },
    {
      id: 'fulfillment',
      image: `${STATIC_FILE_URL}/5b2c73c4ff7a48ae1fa28e8da20f1f3e.png`,
      title: 'Make custom products & packaging.',
      description:
        "We've found thousands of suppliers that can help you manufacture samples, order inventory, and create custom packaging. You can also hire creative agencies and freelancers to help.",
      buttonText: 'Start your business now',
    },
    {
      id: 'e-commerce',
      image: `${STATIC_FILE_URL}/7f324886b99d58930595e6c08f287fb2.png`,
      title: "Stop shipping out of your house. Use Pietra's fulfillment center.",
      description:
        "Save hours each week packaging and shipping products yourself. Store your products and ship them out of Pietra's private fulfillment center. ",
      buttonText: 'Start your business now',
    },
    {
      id: 'education',
      image: `${STATIC_FILE_URL}/5a0dded9f85efdffdba05ef9b59b6036.png`,
      title: 'Setup a storefront and start marketing.',
      description:
        'Pietra provides a storefront and helps you get set up with email marketing. All the tools you need to be successful with your brand online. If you already have a Shopify website, you can connect it to the platform.',
      buttonText: 'Start your business now',
    },
  ],
};

export const NEW_BRAND_PAGE_DATA_V2: NewBrandsPageData = {
  hero: {
    title: 'A new way to dropship with Pietra',
    subtitle: 'The ease of traditional dropshipping but with custom products and domestic shipping',
    bgColor: 'radial-gradient(126.01% 147.85% at 4.11% 0%, #3C44FF 0%, #754FC6 100%)',
    titleColor: '#fff',
    btnText: 'Create Free Pietra Account',
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/e94f5fabf0b172ef10c5ef12eda1fc63.png`,
        title: '170,000+ customizable products',
        description: 'Find a product, customize it, and stand out so nobody can copy your winning products. ',
      },
      {
        image: `${STATIC_FILE_URL}/3140ba3a9b4ab38cccbcf4372e4165a6.png`,
        title: 'Deliver in days, not weeks',
        description:
          'Work with a factory to ship products to Pietra. We store and ship your product from a US-based warehouse.',
      },
      {
        image: `${STATIC_FILE_URL}/4ac6a1e183c9296b850040bf1515834c.png`,
        title: 'Make more money',
        description: 'Get the best price with low minimums and charge a premium for your custom branded products.',
      },
    ],
    showHeroItem: true,
  },
  highlightCards: {
    title: 'A better way to dropship',
    cards: [
      {
        img: `${STATIC_FILE_URL}/4b8649480e9ad816ddc61507e3dac4aa.png`,
        title: 'Vetted products that people love',
        bgColor: 'radial-gradient(126.01% 147.85% at 4.11% 0%, #3C44FF 0%, #754FC6 100%)',
      },
      {
        img: `${STATIC_FILE_URL}/3140ba3a9b4ab38cccbcf4372e4165a6.png`,
        title: 'Use Pietra’s US-based warehouse',
        bgColor: 'radial-gradient(126.01% 147.85% at 4.11% 0%, #3C44FF 0%, #754FC6 100%)',
      },
      {
        img: `${STATIC_FILE_URL}/91ca3aef22c17981e8405dc0e3f892b8.png`,
        title: 'Connect to your Shopify store',
        bgColor: 'radial-gradient(126.01% 147.85% at 4.11% 0%, #3C44FF 0%, #754FC6 100%)',
      },
    ],
  },
  sectionsTitle: 'Trusted by over 250,000 entrepreneurs',
  sections: [
    {
      id: 'sourcing',
      image: `${STATIC_FILE_URL}/a25a6a8356a41d84f6d791e9b3cf7dba.png`,
      title: 'Design bestselling products with AI',
      description:
        "Dream up any product and endlessly customize it. When you're ready, send to Pietra's 1200+ factory network with one click.",
      buttonText: 'Create Free Account',
    },
    {
      id: 'fulfillment',
      image: `${STATIC_FILE_URL}/54792b1b0a320cbd9a4b738113f58738.png`,
      title: 'Source low cost custom packaging to stand out.',
      description:
        'Design packaging and send it to Pietra’s warehouse to package your orders and stand out from the crowd.',
      buttonText: 'Create Free Account',
    },
    {
      id: 'e-commerce',
      image: `${STATIC_FILE_URL}/6ecffbf59835bf6b6130146d906e8da9.png`,
      title: 'Fast delivery with U.S. based fulfillment',
      description:
        'We take care of storage, kitting, shipping, and returns. Customers receive their orders in days, not weeks.',
      buttonText: 'Create Free Account',
    },
    {
      id: 'education',
      image: `${STATIC_FILE_URL}/00d92f6c712a9088c6be18145f587e33.png`,
      title: 'Up to 50% higher profits than dropshipping',
      description:
        'Your products will stand out from the crowd. With a brand, you can charge more and take home more profit. You’ll never go back to the old way of dropshipping again.',
      buttonText: 'Create Free Account',
    },
  ],
};

export const PIETRA_SHOPIFY_PAGE_DATA: NewBrandsPageData = {
  hero: {
    title: "Maximize your Shopify store's profits.",
    subtitle: 'Shopify brands generate up to 50% higher profits with a Pietra membership.',
    bgColor:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), radial-gradient(2142.32% 108.06% at 38.63% -5.93%, #C5A827 0%, #6DAB0A 97.9%)',
    titleColor: '#000',
    preTitleImg: '/svg/pietra-shopify.svg',
    btnText: 'Create Pietra account',
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/66da1b2875f9b968e7bc656b9944ccf7.png`,
        title: 'Lower cost of goods',
        description: "Post a project to Pietra's supplier network and watch factories compete for your business.",
      },
      {
        image: `${STATIC_FILE_URL}/f709c7e5e9aa15cc7540b75d6f5c4e0a.png`,
        title: 'Lower fulfillment fees',
        description:
          "Save 30% or more with Pietra's private 3PL designed for scaling brands across DTC, wholesale, and retail.",
      },
      {
        image: `${STATIC_FILE_URL}/697618d9980d86c639edae6d778eea38.png`,
        title: 'Higher sales revenue',
        description: 'Integrated sales and marketing tools to reach and engage customers across multiple channels.',
      },
    ],
  },
  highlightCards: {
    title: 'Ways to use Pietra',
    cards: [
      {
        img: '/svg/money-green.svg',
        title: 'Lower costs',
      },
      {
        img: '/svg/light-bulb-green.svg',
        title: 'Launch & test faster',
      },
      {
        img: '/svg/process-green.svg',
        title: 'Streamline operations',
      },
    ],
  },
  sectionsTitle: 'Trusted by over 250,000 entrepreneurs',
  sections: [
    {
      id: 'fulfillment',
      image: `${STATIC_FILE_URL}/5b2c73c4ff7a48ae1fa28e8da20f1f3e.png`,
      title: 'Lower costs with a vetted network of suppliers.',
      description:
        'Software to manage existing partners & access to 1200+ more vetted product, packaging, and merch factories to lower costs.',
      buttonText: 'Create Pietra account',
    },
    {
      id: 'e-commerce',
      image: `${STATIC_FILE_URL}/7f324886b99d58930595e6c08f287fb2.png`,
      title: 'Automatic order fulfillment & returns.',
      description:
        'A private 3PL designed for scaling brands across DTC, wholesale, and retail. Save 30% or more with no contracts, no minimums, and $1.15/order (unlimited picks).',
      buttonText: 'Create Pietra account',
    },
    {
      id: 'education',
      image: `${STATIC_FILE_URL}/e9125bf1d271803545c561004d611e88.png`,
      title: 'Increase sales with integrated marketing.',
      description:
        'Instantly list your products on Amazon, TikTok, and more. Reach new and existing customers by SMS and email.',
      buttonText: 'Create Pietra account',
    },
  ],
};

//! LANDING PAGES

export const getDefaultDescription = (text: string) =>
  `Pietra is the easiest, cheapest, and fastest way to launch or scale ${text}. Find factories, set up a 3PL, and create a website all in one place.`;

const getTopFactoriesDesc = (companies: string[]) =>
  `We've found the world's top factories. Pietra's factory network works with: ${companies.join(', ')}, and 100s more.`;

const QUOTE_HAILEY_SANI: LandingPageQuote = {
  quote: "I still have 'pinch me' moments - I can't believe how lucky I got to discover Pietra.",
  author: 'I Love You Baby by Hailey Sani',
  link: 'https://pietra.store/s/ilybaby',
};

const QUOTE_AIEA_GOLF: LandingPageQuote = {
  quote:
    "Pietra's blueprint has been an absolute lifesaver! It lays out everything I need from timelines to suppliers to e-commerce, all in one place.",
  author: 'AIEA Golf',
  link: 'https://pietra.store/s/aiea-golf',
};

const getStepOneDesc = (text: string) => {
  const inputText = text ? `${text} manufacturers` : 'manufacturing partners';
  return `Use sites like Pietra to find vetted ${inputText}. Pietra gives you access to 1200+ vetted factories so you can avoid paying an agency.`;
};

const STEP_2_DESC =
  'Use sites like Pietra to set up order fulfillment. Pietra gives its members 30-50% off fulfillment and storage rates for small businesses and brands.';

const STEP_3_DESC =
  'Use sites like Shopify to set up your e-commerce website. If you want something quicker, Pietra offers free e-commerce storefronts for members.';

const QUOTE_SAMI_CLARKE: LandingPageQuote = {
  quote:
    'We have absolutely loved working with Pietra! Their suppliers are top tier and they make operating a storefront extremely simple.',
  author: 'FORM by Sami Clarke',
  link: 'https://pietra.store/s/form',
};

const SET_UP_FULFILLMENT_DESC =
  'Pietra members get access to a members-only 3PL, but you can totally go at it yourself and contact Shipmonk, Shipbob, or Stord for order fulfillment.';

const CREATE_WEBSITE_DESC =
  'Use Pietra for a super simple e-commerce website or use platforms like Shopify, Squarespace, or BigCommerce to hire a developer and launch a website.';

export const START_A_CLOTHING_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    showPlatformLogo: true,
    title: ['The best place to start and grow a clothing brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/83b7b19a83e38229d7b9dade23b048b1.png`,
        title: 'Lower cost than paying a designer',
      },
      {
        image: `${STATIC_FILE_URL}/5f304ef3e3ba0cfce118d2a62d09a448.png`,
        title: 'Lower cost than major online sites',
      },
      {
        image: `${STATIC_FILE_URL}/59b5795ba0325d66d3e4116a15148059.png`,
        title: 'Lowest cost 3PL in the USA',
      },
      {
        image: `${STATIC_FILE_URL}/65e2925eb9603e465b639611ba827ed1.png`,
        title: 'Open a storefront for just $5',
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a clothing line',
    features: [
      {
        image: `${STATIC_FILE_URL}/1e2ed97a35ac36703788d3d918256379.png`,
        title: 'Work with suppliers to design custom products & packaging.',
        description:
          'Pietra gives you tools so you avoid having to pay a design agency to visualize your designs for suppliers and manufacturers.',
        buttonText: 'Start Designing',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/56b564085be6a1738eec7ec94664b78f.png`,
        title: 'Work directly with suppliers to save up to 90% of samples and production.',
        description:
          'Start chatting with suppliers today to turn your designs into reality. Pietra finds you the lowest minimums on the internet!',
        buttonText: 'Find a supplier to work with',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/50d1f95022440348c82a9ac9dcaffbfe.png`,
        title: 'Pietra helps you ship orders to customers cheaper and faster.',
        description:
          'That’s right. Pietra offers clothing brand owners the most affordable 3PL rates in the USA. If you can find better prices, we’ll match it!',
        buttonText: 'Explore shipping options',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/f14ba1d0c591acfa469ab4916fdf5531.png`,
        title: 'Works with your Shopify site or we can help you create one!',
        description:
          'Get a Pietra storefront for just $5 or connect your existing Shopify website to Pietra to start selling.',
        buttonText: 'Setup your store',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a clothing brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a clothing line'),
  },
};
export const START_AN_EYESHADOW_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start an eyeshadow line in 3 steps'],
    subtitle: getDefaultDescription('an eyeshadow line'),
    button: {
      text: [{ text: 'Start your own eyeshadow line' }, { text: 'today', style: 'underline' }],
    },
    quote: QUOTE_AIEA_GOLF,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/21f96cd5f77a6cf51a99f30c5fbf0b99.png`,
        title: 'Step 1: Find an eyeshadow manufacturer',
        description: getStepOneDesc('eyeshadow'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/f3a51452ac421ac0c071fa06b8804253.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start an eyeshadow line',
    features: [
      {
        image: `${STATIC_FILE_URL}/326e47fc21535bb49871d800b9beac14.png`,
        title: 'Find a vetted eyeshadow factory on Pietra.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Sephora',
          'Ulta',
          'Boots',
          'Walgreens',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/3e8fcb3941d998d260233093a73c8be4.png`,
        title: 'Create your e-commerce website.',
        description: CREATE_WEBSITE_DESC,
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting an eyeshadow brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('an eyeshadow line'),
  },
};
export const START_A_HAIR_PRODUCTS_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start a hair products line in 3 steps'],
    subtitle: getDefaultDescription('a hair products line'),
    button: {
      text: [{ text: 'Start your own hair products line' }, { text: 'today', style: 'underline' }],
    },
    quote: QUOTE_AIEA_GOLF,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/7f4e5765483546c0eaea5aaef722e877.png`,
        title: 'Step 1: Find a hair products manufacturer',
        description: getStepOneDesc('hair products'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/1771887be09b4c308debbd509b5fa076.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a hair products line',
    features: [
      {
        image: `${STATIC_FILE_URL}/3ba0fb8e647c261265944bef63d9a7c2.png`,
        title: 'Find a vetted hair products factory on Pietra.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Sephora',
          'Ulta',
          'Boots',
          'Walgreens',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/cf59ed9d88c0d241975354bea55fb805.png`,
        title: 'Create your e-commerce website.',
        description: CREATE_WEBSITE_DESC,
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a hair products brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a hair products line'),
  },
};
export const START_A_MAKEUP_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start a makeup line in 3 steps'],
    subtitle: getDefaultDescription('a makeup line'),
    button: {
      text: [{ text: 'Start your own makeup line' }, { text: 'today', style: 'underline' }],
    },
    quote: QUOTE_AIEA_GOLF,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/c481dc94e39996356f33b34979ddf9d2.png`,
        title: 'Step 1: Find a makeup manufacturer',
        description: getStepOneDesc('makeup'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/8c9c372eeba0079b4426b479daeef9f5.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a makeup line',
    features: [
      {
        image: `${STATIC_FILE_URL}/1fec0aa1b904e1edb744bb1b6ac28c80.png`,
        title: 'Find a vetted makeup factory on Pietra.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Sephora',
          'Ulta',
          'Boots',
          'Walgreens',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/ebf04d0afcd1f6beeace5c50a9db315c.png`,
        title: 'Create your e-commerce website.',
        description: CREATE_WEBSITE_DESC,
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a makeup brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a makeup line'),
  },
};
export const START_A_LIP_GLOSS_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start a lip gloss line in 3 steps'],
    subtitle: getDefaultDescription('a lip gloss line'),
    button: {
      text: [{ text: 'Start your own lip gloss line' }, { text: 'today', style: 'underline' }],
    },
    quote: QUOTE_AIEA_GOLF,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/9acc8cb95b63d50a181f25ab333b7282.png`,
        title: 'Step 1: Find a lip gloss manufacturer',
        description: getStepOneDesc('lip gloss'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/7d9a001bb0f8d039668c12559aa92197.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a lip gloss line',
    features: [
      {
        image: `${STATIC_FILE_URL}/6a7080dd2cb8c857c0bd5303b6ab8f61.png`,
        title: 'Find a vetted lip gloss factory on Pietra.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Sephora',
          'Ulta',
          'Boots',
          'Walgreens',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/5e19cf7af6c690e78d1b2c2a00632576.png`,
        title: 'Create your e-commerce website.',
        description: CREATE_WEBSITE_DESC,
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a lip gloss brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a lip gloss line'),
  },
};
export const START_A_SKINCARE_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    showPlatformLogo: true,
    title: ['The best place to start and grow an skincare brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/ef80b75e1bf5020c8229f5a7375a9b2d.png`,
        title: 'Lower cost than paying a designer',
      },
      {
        image: `${STATIC_FILE_URL}/42c9bdf77cebcc460132134c95d516f1.png`,
        title: 'Lower cost than major online sites',
      },
      {
        image: `${STATIC_FILE_URL}/59b5795ba0325d66d3e4116a15148059.png`,
        title: 'Lowest cost 3PL in the USA',
      },
      {
        image: `${STATIC_FILE_URL}/65e2925eb9603e465b639611ba827ed1.png`,
        title: 'Open a storefront for just $5',
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a skincare line',
    features: [
      {
        image: `${STATIC_FILE_URL}/b0fd0860adc26cba69c0edd9b6ce34ba.png`,
        title: 'Work with suppliers to customize private label skincare or create your own formulas.',
        description:
          'Pietra gives you tools so you avoid having to pay a design agency to visualize your designs for suppliers & manufacturers.',
        buttonText: 'Start Designing',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/7840880a71c640c096cf92ec7e67e31b.png`,
        title: 'Work directly with  suppliers to save up to 90% of samples and production.',
        description:
          'Start chatting with suppliers today to turn your designs into reality. Pietra finds you the lowest minimums on the internet!',
        buttonText: 'Find a supplier to work with',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/50d1f95022440348c82a9ac9dcaffbfe.png`,
        title: 'Use Pietra’s 3PL to lower costs and speed up shipping times.',
        description:
          'That’s right. Pietra offers athleisure brand owners the most affordable 3PL rates in the USA. If you can find better prices, we’ll match it!',
        buttonText: 'Explore shipping options',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/f14ba1d0c591acfa469ab4916fdf5531.png`,
        title: 'Works with your Shopify site or we can help you create one!',
        description:
          'Get a Pietra storefront for just $5 or connect your existing Shopify website to Pietra to start selling.',
        buttonText: 'Setup your store',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a skincare brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a skincare line'),
  },
};
export const START_A_CANDLE_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    showPlatformLogo: true,
    title: ['The best place to start and grow an candle & fragrance brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/f34427fce0f74a8bdacc3a45f2900594.png`,
        title: 'Lower cost than paying a designer',
      },
      {
        image: `${STATIC_FILE_URL}/40d3ddf7207bc68dcc5188d33afa4010.png`,
        title: 'Lower cost than major online sites',
      },
      {
        image: `${STATIC_FILE_URL}/59b5795ba0325d66d3e4116a15148059.png`,
        title: 'Lowest cost 3PL in the USA',
      },
      {
        image: `${STATIC_FILE_URL}/65e2925eb9603e465b639611ba827ed1.png`,
        title: 'Open a storefront for just $5',
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a candle line',
    features: [
      {
        image: `${STATIC_FILE_URL}/6c379c3b854af896643de1d750d658a2.png`,
        title: 'Work with suppliers to design custom products & packaging.',
        description:
          'Pietra gives you tools so you avoid having to pay a design agency to visualize your designs for suppliers & manufacturers.',
        buttonText: 'Start Designing',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/7840880a71c640c096cf92ec7e67e31b.png`,
        title: 'Work directly with suppliers  to save up to 90% of samples and production.',
        description:
          'Start chatting with suppliers today to turn your designs into reality. Pietra finds you the lowest minimums on the internet!',
        buttonText: 'Find a supplier to work with',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/50d1f95022440348c82a9ac9dcaffbfe.png`,
        title: 'Use Pietra’s 3PL to lower costs and speed up shipping times.',
        description:
          'That’s right. Pietra offers candle brand owners the most affordable 3PL in the USA. If you can find better prices, we’ll match it!',
        buttonText: 'Explore shipping options',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/f14ba1d0c591acfa469ab4916fdf5531.png`,
        title: 'Set up a storefront or connect your Shopify to start selling!',
        description:
          'Get a Pietra storefront for just $5 or connect your existing Shopify website to Pietra to start selling.',
        buttonText: 'Setup your store',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a candle brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a candle line'),
  },
};
export const START_A_ATHLEISURE_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    showPlatformLogo: true,
    title: ['The best place to start and grow an athleisure brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/66cadbaeaace26df849015461705f64d.png`,
        title: 'Lower cost than paying a designer',
      },
      {
        image: `${STATIC_FILE_URL}/5f304ef3e3ba0cfce118d2a62d09a448.png`,
        title: 'Lower cost than major online sites',
      },
      {
        image: `${STATIC_FILE_URL}/59b5795ba0325d66d3e4116a15148059.png`,
        title: 'Lowest cost 3PL in the USA',
      },
      {
        image: `${STATIC_FILE_URL}/65e2925eb9603e465b639611ba827ed1.png`,
        title: 'Open a storefront for just $5',
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a athleisure line',
    features: [
      {
        image: `${STATIC_FILE_URL}/c2c02c852b2e536bc74d067470973705.png`,
        title: 'Work with suppliers to design custom products & packaging.',
        description:
          'Pietra gives you tools so you avoid having to pay a design agency to visualize your designs for suppliers & manufacturers.',
        buttonText: 'Start Designing',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/7840880a71c640c096cf92ec7e67e31b.png`,
        title: 'Work directly with suppliers to save up to 90% of samples and production.',
        description:
          'Start chatting with suppliers today to turn your designs into reality. Pietra finds you the lowest minimums on the internet!',
        buttonText: 'Find a supplier to work with',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/50d1f95022440348c82a9ac9dcaffbfe.png`,
        title: 'Use Pietra’s 3PL to lower costs and speed up shipping times.',
        description:
          'That’s right. Pietra offers small business owners the most affordable 3PL in the USA. If you can find better prices, we’ll match it!',
        buttonText: 'Explore shipping options',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/f14ba1d0c591acfa469ab4916fdf5531.png`,
        title: 'Set up a storefront or connect your Shopify to start selling!',
        description:
          'Get a Pietra storefront for just $5 or connect your existing Shopify website to Pietra to start selling.',
        buttonText: 'Setup your store',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a jewelry brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a athleisure line'),
  },
};
export const START_A_JEWELRY_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    showPlatformLogo: true,
    title: ['The best place to start and grow an jewelry brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/8cfeeddbc02c6d2e1e96ae20e524c408.png`,
        title: 'Lower cost than paying a designer',
      },
      {
        image: `${STATIC_FILE_URL}/5666ee31784966700ce3f62c5f18e509.png`,
        title: 'Lower cost than major online sites',
      },
      {
        image: `${STATIC_FILE_URL}/59b5795ba0325d66d3e4116a15148059.png`,
        title: 'Lowest cost 3PL in the USA',
      },
      {
        image: `${STATIC_FILE_URL}/65e2925eb9603e465b639611ba827ed1.png`,
        title: 'Open a storefront for just $5',
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a jewelry line',
    features: [
      {
        image: `${STATIC_FILE_URL}/e7ec8704c800db13f83889f8cce0c656.png`,
        title: 'Work with suppliers to design custom products & packaging.',
        description:
          'Pietra gives you tools so you avoid having to pay a design agency to visualize your designs for suppliers & manufacturers.',
        buttonText: 'Start Designing',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/7840880a71c640c096cf92ec7e67e31b.png`,
        title: 'Work directly with suppliers to save up to 90% of samples and production.',
        description:
          'Start chatting with suppliers today to turn your designs into reality. Pietra finds you the lowest minimums on the internet!',
        buttonText: 'Find a supplier to work with',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/50d1f95022440348c82a9ac9dcaffbfe.png`,
        title: 'Use Pietra’s 3PL to lower costs and speed up shipping times.',
        description:
          'That’s right. Pietra offers jewelry brand owners the most affordable 3PL in the USA. If you can find better prices, we’ll match it!',
        buttonText: 'Explore shipping options',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/f14ba1d0c591acfa469ab4916fdf5531.png`,
        title: 'Set up a storefront or connect your Shopify to start selling!',
        description:
          'Get a Pietra storefront for just $5 or connect your existing Shopify website to Pietra to start selling.',
        buttonText: 'Setup your store',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a jewelry brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a jewelry line'),
  },
};
export const START_A_SUNGLASSES_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start a sunglasses line in 3 steps'],
    subtitle: getDefaultDescription('a sunglasses line'),
    button: {
      text: [{ text: 'Start your own sunglasses line' }, { text: 'today', style: 'underline' }],
    },
    quote: QUOTE_AIEA_GOLF,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/9d736e941bdda11a41038f98af51a1a9.png`,
        title: 'Step 1: Find a sunglasses manufacturer',
        description: getStepOneDesc('sunglasses'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/338b7daea556d10b636dfbce60ae09ec.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a sunglasses line',
    features: [
      {
        image: `${STATIC_FILE_URL}/d58998bb93a686360b2662eff7f8e1f8.png`,
        title: 'Find a vetted sunglasses factory on Pietra.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Sephora',
          'Ulta',
          'Boots',
          'Walgreens',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/b3b561c7b768ba3adc37c8d093905641.png`,
        title: 'Create your e-commerce website.',
        description: getDefaultDescription('a sunglasses line'),
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a sunglasses brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a sunglasses line'),
  },
};
export const START_A_LEATHER_GOODS_LINE: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start a leather goods line in 3 steps'],
    subtitle: getDefaultDescription('a leather goods line'),
    button: {
      text: [{ text: 'Start your own leather goods line' }, { text: 'today', style: 'underline' }],
    },
    quote: QUOTE_AIEA_GOLF,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/66661fd4941786284351023eaa271863.png`,
        title: 'Step 1: Find a leather goods manufacturer',
        description: getStepOneDesc('leather goods'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/db86cffbdc424b4cd2af9e94bd64f408.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start a leather goods line',
    features: [
      {
        image: `${STATIC_FILE_URL}/a43cc991ad00d342cd1b925fe80e9eda.png`,
        title: 'Find a vetted leather goods factory on Pietra.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Sephora',
          'Ulta',
          'Boots',
          'Walgreens',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/bc3b418701780ad2d1541dd8bed34409.png`,
        title: 'Create your e-commerce website.',
        description: getDefaultDescription('a leather goods line'),
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a leather goods brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a leather goods line'),
  },
};

export const ALTERNATIVE_TO_ETSY: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Move from Etsy to Pietra & get paid daily'],
    subtitle: 'Move your Etsy storefront to Pietra and get paid daily for sales',
    button: {
      text: [{ text: 'Open your Pietra storefront' }],
    },
    quote: QUOTE_HAILEY_SANI,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/01663d7de9f2cc46c407236e7e956c38.png`,
        title: 'Step 1: Sign up for a Pietra membership',
        description: getStepOneDesc('clothing'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: "Step 2: Send your inventory to Pietra's 3PL",
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/6d7e0fd09de505dce797fc96a3a953a8.png`,
        title: 'Step 3: Set up storefront and start selling',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra protects you from Etsy payment delays',
    features: [
      {
        image: `${STATIC_FILE_URL}/bb816213e09d838d76a6023bd640529d.png`,
        title: 'Sign up for Pietra to get members only access.',
        description:
          'Pietra is a one-stop shop for you to run your e-commerce business. It provides sourcing tools, a 3PL, and a storefront for you to manage your business.',
        buttonText: 'Find vetted manufacturers',
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: "Send your inventory to Pietra's 3PL with same day service.",
        description:
          'Need to start fulfilling quickly? Click a few buttons and send your inventory directly to Pietra and Pietra can start fulfilling your orders asap.',
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/42cc5febb0981f1ca2a7b3d91dd800e6.png`,
        title: 'Create your Pietra strorefront like your Etsy shop, but with better rates.',
        description:
          "Pietra's storefront has all the power of an Etsy storefront at a fraction of the cost. Enjoy no listing fees and a 5% processing fee on each sale vs. Etsy's 18%.",
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a clothing brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('your brand'),
  },
};

export const FIND_CLOTHING_MANUFACTURER: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Finding clothing manufacturers in 2024'],
    subtitle: getDefaultDescription('a clothing line'),
    button: {
      text: [{ text: "Browse Pietra's 1200+ manufacturer network" }],
    },
    quote: QUOTE_HAILEY_SANI,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/17b66c6226ac6f4908e192fbfdada7ba.png`,
        title: 'Use Pietra to find vetted factories and manufacturing partners.',
      },
      {
        image: `${STATIC_FILE_URL}/ed355a8c9a723276d4960c95868736ee.png`,
        title: 'Create prototypes to get accurate quotes for samples and production.',
      },
      {
        image: `${STATIC_FILE_URL}/82d16d3a097e75c92df8b4901f17d18a.png`,
        title: 'Pay deposits securely by credit card or low cost wire transfers.',
      },
      {
        image: `${STATIC_FILE_URL}/d5e5cc9e991d63e11492fa986d040a69.png`,
        title: 'Get the best prices from factories that work with top brands in the USA.',
      },
    ],
    rating: `${STATIC_FILE_URL}/3368673736ee7dfff7c9ad1948b1c9b6.svg`,
  },
  features: {
    title: 'How you can find clothing manufacturers',
    features: [
      {
        image: `${STATIC_FILE_URL}/7e43ca59e679ab2e907644e3c8ce86cf.png`,
        title: 'Pietra saves you time finding suppliers and manufacturers in 2024.',
        buttonText: 'Browse all suppliers',
        dicountText: 'Bonus: Get 10% off your first order!',
        description: getTopFactoriesDesc([
          'Nike',
          'Gucci',
          'Sephora',
          'Swarovski',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/c3b2d56ccb91db51d57672faafe557e0.png`,
        title: 'Save money working directly with suppliers who provide low minimums.',
        description:
          'Pietra negotiates minimums and shipping prices for all suppliers. This is why it’s most beneficial to find your supplier through Pietra’s manufacturing network. Best of all, it’s completely free to browse.',
        buttonText: 'Browse all suppliers',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/8c51cfc9925e8fcb22ba34ca3dec4c71.png`,
        title: 'Look for sourcing platforms that do the hard work for you.',
        description:
          'Websites like Pietra not only find all the best suppliers, manufacturers, and consultants, but they also give you access to upfront prices for sampling and production. Very useful when picking a supplier.',
        buttonText: 'Browse all suppliers',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a clothing brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a clothing line'),
  },
};

export const OPEN_PIETRA_STOREFRONT: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    showPlatformLogo: true,
    title: ['The best place to start and grow an e-commerce brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/ed355a8c9a723276d4960c95868736ee.png`,
        title: 'Lower cost than paying a designer',
      },
      {
        image: `${STATIC_FILE_URL}/8034e85ad1482e0dfc94a2ee4b1b9622.png`,
        title: 'Lower cost than major online sites',
      },
      {
        image: `${STATIC_FILE_URL}/59b5795ba0325d66d3e4116a15148059.png`,
        title: 'Lowest cost 3PL in the USA',
      },
      {
        image: `${STATIC_FILE_URL}/65e2925eb9603e465b639611ba827ed1.png`,
        title: 'Open a storefront for just $5',
      },
    ],
  },
  features: {
    title: 'The best place to start and grow an e-commerce brand online.',
    features: [
      {
        image: `${STATIC_FILE_URL}/788e4613ddf4433d2b40ed2208e908cf.png`,
        title: 'Work with suppliers to design custom products & packaging.',
        description:
          'Pietra gives you tools so you avoid having to pay a design agency to visualize your designs for suppliers and manufacturers.',
        buttonText: 'Start Designing',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/56b564085be6a1738eec7ec94664b78f.png`,
        title: 'Work directly with vetted suppliers to save up to 90% of samples and production.',
        description:
          'Start chatting with suppliers today to turn your designs into reality. Pietra finds you the lowest minimums on the internet!',
        buttonText: 'Find a supplier to work with',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/50d1f95022440348c82a9ac9dcaffbfe.png`,
        title: 'Pietra helps you ship orders to customers cheaper and faster.',
        description:
          'That’s right. Pietra offers small business owners the most affordable 3PL in the USA. If you can find better prices, we’ll match it!',
        buttonText: 'Explore Shipping Options',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/f14ba1d0c591acfa469ab4916fdf5531.png`,
        title: 'Works with your Shopify site or we can help you create one!',
        description:
          'Get a Pietra storefront for just $5 or connect your existing Shopify website to Pietra to start selling.',
        buttonText: 'Setup your store',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a clothing brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('your brand'),
  },
};

export const START_ATHLEISURE_FITNESS_BRAND: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Start an athleisure or fitness brand in 3 steps'],
    subtitle: getDefaultDescription('an athleisure and fitness line'),
    button: {
      text: [{ text: 'Start your own athleisure line today' }],
    },
    quote: QUOTE_SAMI_CLARKE,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/d250bab10de7d1e84c4316fde5146ed7.png`,
        title: 'Step 1: Find an athliesure manufacturer',
        description: getStepOneDesc('clothing'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Pick your 3PL for fulfillment',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/ee291f43ef4d928d626f2e83cd39f5aa.png`,
        title: 'Step 3: Set up your website',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start an athleisure line',
    features: [
      {
        image: `${STATIC_FILE_URL}/2c831110b1115f8939e4e108b42bd2ff.png`,
        title: 'Find a vetted athleisure or activewear factory.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Nike',
          'Gucci',
          'Sephora',
          'Swarovski',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/d65dd7d1f491cdf236d9eec7c10bd8b9.png`,
        title: 'After sourcing, set up your order fulfillment.',
        description: SET_UP_FULFILLMENT_DESC,
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/acdbb658566b54c5372d20e103dbb677.png`,
        title: 'Create your athleisure e-commerce website.',
        description: CREATE_WEBSITE_DESC,
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting an athleisure brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('an athleisure and fitness line'),
  },
};

export const FIND_ATHLEISURE_FITNESS_SUPPLIERS: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    title: ['Finding athleisure & fitness suppliers'],
    subtitle: getDefaultDescription('an athleisure and fitness line'),
    button: {
      text: [{ text: 'Find athleisure & fitness suppliers' }],
    },
    quote: QUOTE_SAMI_CLARKE,
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/d250bab10de7d1e84c4316fde5146ed7.png`,
        title: 'Step 1: Use a vetted athleisure sourcing platform',
        description: getStepOneDesc('clothing'),
      },
      {
        image: `${STATIC_FILE_URL}/bfa68fc382b39c9713304456c917478b.png`,
        title: 'Step 2: Search for items you want to make',
        description: STEP_2_DESC,
      },
      {
        image: `${STATIC_FILE_URL}/ee291f43ef4d928d626f2e83cd39f5aa.png`,
        title: 'Step 3: Start sampling from factories',
        description: STEP_3_DESC,
      },
    ],
  },
  features: {
    title: 'Pietra makes it easy to start an athleisure line',
    features: [
      {
        image: `${STATIC_FILE_URL}/c845e3ae83d19bc39b18b8bba3bfbd63.png`,
        title: 'Use Pietra to access hundreds of manufacturers & wholesale suppliers.',
        description:
          "We've found the world's top fitness, athleisure, activewear factories. Access wholesalers and private labeler networks as part of your Pietra membership.",
        buttonText: 'Find vetted manufacturers',
      },
      {
        image: `${STATIC_FILE_URL}/ae56965ab9d4625fd129228cdb66c000.png`,
        title: 'Hire a consultant to help you source at your price point.',
        description:
          'You can use Pietra for hire creative consultants to help you through the design and sourcing process. You can also talk directly to print shops to see if they can offer different services to help you get started.',
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/7aa511c94aea03526c1ec33bb3e5da32.png`,
        title: 'Use sourcing platforms that do the hard work for you.',
        description:
          'Websites like Pietra not only find all the best suppliers, manufacturers, and consultants, but they also give you access to upfront prices for sampling and production. Very useful when picking a supplier.',
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting an athleisure brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('an athleisure and fitness line'),
  },
};

export const ALTERNATIVE_TO_ALIBABA: LandingPageData = {
  hero: {
    type: LandingPageHeroType.COMPARISON,
    title: ['The #1 Alternative To Alibaba'],
    subtitle:
      'Pietra has created a global, vetted, supplier network that is willing to work with you to bring lower costs, lower minimums, and higher quality.',
    button: {
      text: [{ text: "Browse Pietra's sourcing marketplace" }],
    },
    quote: QUOTE_HAILEY_SANI,
    competitorComparisons: [
      {
        pietra: {
          logo: '/logos/pietra-logo.svg',
          items: ['Vetted trusted manufacturers', 'Global Network', 'High Quality'],
        },
        competitor: {
          name: 'Alibaba',
          logo: `${STATIC_FILE_URL}/cd7c6ffaf79ecafc03f80b819989a9f6.svg`,
          items: ['Unchecked manufacturers', 'Only Chinese factories', 'Unknown Quality'],
        },
      },
    ],
  },
  features: {
    title: "Pietra protects you from Alibaba's quality issues",
    features: [
      {
        image: `${STATIC_FILE_URL}/a248e902ff69f73cc60eeea271fed156.png`,
        title: '1200+ vetted product & packaging manufacturers & wholesalers.',
        buttonText: 'Find vetted manufacturers',
        description: getTopFactoriesDesc([
          'Nike',
          'Gucci',
          'Sephora',
          'Swarovski',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/0bd4ffe62296c77defc1d541868e0a5d.png`,
        title: 'Source sustainable packaging options from vetted packaging suppliers.',
        description:
          "Members get special pricing to the world's top packaging suppliers. Polymailers, cardstock boxes, shipping mailers, stickers, and more. All at members-only pricing.",
        buttonText: 'Save 30%+',
      },
      {
        image: `${STATIC_FILE_URL}/54cf3a2ba222e626fc525e407ecdbd69.png`,
        title: 'High quality suppliers from around the world. Used by the biggest brands in the USA.',
        description:
          "Pietra negotiates with the world's best suppliers to bring you lower MOQs, lower shipping costs, and allows you to work directly with trained factories. Save time and money sourcing with Pietra's members-only factory network.",
        buttonText: 'Start sourcing from the best',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting or scaling their brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('your brand'),
  },
};

export const ALTERNATIVE_TO_SHIPBOB: LandingPageData = {
  hero: {
    type: LandingPageHeroType.COMPARISON,
    title: ['The #1 Alternative To Shipbob'],
    subtitle:
      "Pietra's members only fulfillment options are 30-50% cheaper than Shipbob, Shipmonk, and leading competitors.",
    button: {
      text: [{ text: "Get access to Pietra's members only 3PL" }],
      mobileText: [{ text: "Access Pietra's members only 3PL" }],
    },
    quote: QUOTE_HAILEY_SANI,
    competitorComparisons: [
      {
        pietra: {
          logo: '/logos/pietra-logo.svg',
          items: ['Fulfillment rates as low as $1/order', 'No contracts or lock in', 'Send items to 3PL today'],
        },
        competitor: {
          name: 'Shipbob-Shipmonk',
          logo: `${STATIC_FILE_URL}/e501bb8e8d430c9147d7f934eb88a212.svg`,
          items: ['Avg. rates $2-3/per item', 'Contracts and yearly lock in', 'Several weeks to get set up'],
        },
      },
    ],
  },
  features: {
    title: 'Pietra saves you fulfillment costs over Shipbob, Shipmonk, and others',
    features: [
      {
        image: `${STATIC_FILE_URL}/274735086b3673ea18eb4c781ec16aea.png`,
        title: 'A members-only 3PL that actually works the way you want. And 30% lower cost.',
        description:
          "Pietra provides low 3PL rates to members. We've built a no-frills fulfillment center because we couldn't work with the others. Our model is straight forward, deliver basic global fulfillment services that just work. No lock in, no minimums.",
        buttonText: "Access Pietra's 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/d05890a7205973863f536e9ee6b1ed48.png`,
        title: "Send inventory to Pietra's 3PL Today. Not in 3 weeks.",
        description:
          'Need to start fulfilling quickly? Click a few buttons and send your inventory directly to Pietra and Pietra can start fulfilling your orders asap.',
        buttonText: "Join Pietra's members only 3PL",
      },
      {
        image: `${STATIC_FILE_URL}/478610201faa6ce96227d444e443a50d.png`,
        title: 'Customer support from humans not robots.',
        description:
          'We provide high-touch, high quality customer support to our members. Every time you need help, a human will respond right away. Seriously, give us a call.',
        buttonText: 'Set up your Pietra site',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting or scaling their brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('your brand'),
  },
};

export const ALTERNATIVE_TO_CHANNEL_ADVISOR: LandingPageData = {
  hero: {
    type: LandingPageHeroType.COMPARISON,
    title: ['The #1 Alternative To Channel Advisor'],
    subtitle:
      "Don't hire agencies to help you sell more online. Use Pietra to list your products on different sales channels and grow your sales with ease.",
    button: {
      text: [{ text: 'Start listing your products & growing sales' }],
      mobileText: [{ text: 'List your products & grow sales' }],
    },
    quote: QUOTE_HAILEY_SANI,
    competitorComparisons: [
      {
        pietra: {
          logo: '/logos/pietra-logo.svg',
          items: ['$39 monthly subscription', 'No lock-in', 'Get started today'],
        },
        competitor: {
          name: 'Channel Advisor',
          logo: `${STATIC_FILE_URL}/db3034882f20093be7161cbef6c63dee.svg`,
          items: ['$1,300 monthly subscription', '12-month agreement', '4-6 week onboarding'],
        },
      },
    ],
  },
  features: {
    title: 'Pietra saves you time and money when listing on various channels',
    features: [
      {
        image: `${STATIC_FILE_URL}/1bf8c7da6bb5b1bc3a18d7a0f4ca43db.png`,
        title: 'Sell your products online, offline, wholesale, and retail in a few clicks.',
        description:
          "Looking to find retail, wholesale, and more online buyers? Pietra's sales network gives you the power of a massive sales team and exposure to over 85,000 buyers for your products. All set up in a few clicks.",
        buttonText: "List on Pietra's sales network",
      },
      {
        image: `${STATIC_FILE_URL}/9fbb5a3431508d0f0572c0421f7d7dcc.png`,
        title: 'Pietra handles all the backend logistics for every sale. Just sit back and collect your payout.',
        description:
          "Members get access to a global sales network that is designed to increase visibility of your brand. Send items to Pietra's 3PL and connect as many sales channels as you want.",
        buttonText: 'Start adding sales channels',
      },
      {
        image: `${STATIC_FILE_URL}/42cc5febb0981f1ca2a7b3d91dd800e6.png`,
        title: 'Weekly payouts directly deposited into your account, every week.',
        description:
          "No lengthy contracts, lock-in, or minimums. Each sales channel lists their fees and you pay exactly what is listed in Pietra's portal.",
        buttonText: 'Start making more money',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting or scaling their brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('your brand'),
  },
};

export const NEW_BRAND_GENERIC_PAGE_DATA: LandingPageData = {
  hero: {
    type: LandingPageHeroType.SPOTLIGHT,
    showPietraLogo: false,
    showBrandLogo: false,
    title: ['The best place to start and grow an e-commerce brand online.'],
    button: {
      text: [{ text: 'Create Free Account' }],
    },
    spotlightItems: [
      {
        image: `${STATIC_FILE_URL}/5170b41aae1d2b3c171662d529a95f05.png`,
        title: 'Use Pietra to find vetted factories and manufacturing partners.',
      },
      {
        image: `${STATIC_FILE_URL}/c70aba02a6c8e4442f100fdfec2fb032.png`,
        title: 'Create prototypes to get accurate quotes for samples and production.',
      },
      {
        image: `${STATIC_FILE_URL}/e20770bf692fbf38181a3650ec40191f.png`,
        title: 'Pay deposits securely by credit card or low cost wire transfers.',
      },
      {
        image: `${STATIC_FILE_URL}/b95be16468d2a59216baa4cfb0725677.png`,
        title: 'Get the best prices from factories that work with top brands in the USA.',
      },
    ],
  },
  features: {
    title: 'How you can find clothing manufacturers',
    features: [
      {
        image: `${STATIC_FILE_URL}/7e43ca59e679ab2e907644e3c8ce86cf.png`,
        title: 'Pietra saves you time finding suppliers and manufacturers in 2024.',
        buttonText: 'Browse all suppliers',
        dicountText: 'Bonus: Get 10% off your first order!',
        description: getTopFactoriesDesc([
          'Nike',
          'Gucci',
          'Sephora',
          'Swarovski',
          'KKW Beauty',
          'Ralph Lauren',
          'Shein',
          'Zara',
          'TopShop',
          'Revolve',
        ]),
      },
      {
        image: `${STATIC_FILE_URL}/29c04e51744f8a3338bcef1f4fffd077.png`,
        title: 'Save money working directly with suppliers who provide low minimums.',
        description:
          'Pietra negotiates minimums and shipping prices for all suppliers. This is why it’s most beneficial to find your supplier through Pietra’s manufacturing network. Best of all, it’s completely free to browse.',
        buttonText: 'Browse all suppliers',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
      {
        image: `${STATIC_FILE_URL}/21d567b01e94acf38f668c0fb4263691.png`,
        title: 'Look for sourcing platforms that do the hard work for you.',
        description:
          'Websites like Pietra not only find all the best suppliers, manufacturers, and consultants, but they also give you access to upfront prices for sampling and production. Very useful when picking a supplier.',
        buttonText: 'Browse all suppliers',
        dicountText: 'Bonus: Get 10% off your first order!',
      },
    ],
  },
  reviewsTitle: 'Reviews from entrepreneurs starting a clothing brand',
  footer: {
    title: 'Become an entrepreneur today',
    description: getDefaultDescription('a clothing line'),
  },
};

export const pagePaths: {
  [key: string]: {
    title: string;
    description: string;
    path: string;
    data: LandingPageData;
    page: string;
    isBuilderIOPage?: boolean;
  };
} = {
  'alternative-to-alibaba': {
    title: 'The #1 Alternative To Alibaba',
    description:
      'Pietra has created a global, vetted, supplier network that is willing to work with you to bring lower costs, lower minimums, and higher quality.',
    path: '/l/alternative-to-alibaba',
    data: ALTERNATIVE_TO_ALIBABA,
    page: SEO_META_PAGES.LANDING_ALTERNATIVE_TO_ALIBABA,
  },
  'alternative-to-channel-advisor': {
    title: 'The #1 Alternative To Channel Advisor',
    description:
      "Don't hire agencies to help you sell more online. Use Pietra to list your products on different sales channels and grow your sales with ease.",
    path: '/l/alternative-to-channel-advisor',
    data: ALTERNATIVE_TO_CHANNEL_ADVISOR,
    page: SEO_META_PAGES.LANDING_ALTERNATIVE_TO_CHANNEL_ADVISOR,
  },
  'alternative-to-etsy': {
    title: 'Open Your Pietra Storefront',
    description: getDefaultDescription('your brand'),
    path: '/l/alternative-to-etsy',
    data: ALTERNATIVE_TO_ETSY,
    page: SEO_META_PAGES.LANDING_ALTERNATIVE_TO_ETSY,
  },
  'alternative-to-shipbob': {
    title: 'The #1 Alternative To Shipbob',
    description:
      'Pietra has created a global, vetted, supplier network that is willing to work with you to bring lower costs, lower minimums, and higher quality.',
    path: '/l/alternative-to-shipbob',
    data: ALTERNATIVE_TO_SHIPBOB,
    page: SEO_META_PAGES.LANDING_ALTERNATIVE_TO_SHIPBOB,
  },
  'finding-athleisure-and-fitness-suppliers': {
    title: 'Finding athleisure & fitness suppliers',
    description: getDefaultDescription('an athleisure and fitness line'),
    path: '/l/finding-athleisure-and-fitness-suppliers',
    data: FIND_ATHLEISURE_FITNESS_SUPPLIERS,
    page: SEO_META_PAGES.LANDING_FINDING_CLOTHING_SUPPLIERS,
  },
  'finding-clothing-manufacturers': {
    isBuilderIOPage: true,
    title: 'Finding clothing manufacturers 2024',
    description: getDefaultDescription('a clothing line'),
    path: '/l/finding-clothing-manufacturers',
    data: FIND_CLOTHING_MANUFACTURER,
    page: SEO_META_PAGES.LANDING_FINDING_CLOTHING_SUPPLIERS,
  },
  'open-your-pietra-storefront': {
    isBuilderIOPage: true,
    title: 'The best place to start and grow an e-commerce brand online.',
    description: getDefaultDescription('your brand'),
    path: '/l/open-your-pietra-storefront',
    data: OPEN_PIETRA_STOREFRONT,
    page: SEO_META_PAGES.LANDING_OPEN_PIETRA_STOREFRONT,
  },
  'start-a-clothing-line': {
    isBuilderIOPage: true,
    title: 'The best place to start and grow a clothing brand online.',
    description: getDefaultDescription('a clothing line'),
    path: '/l/start-a-clothing-line',
    data: START_A_CLOTHING_LINE,
    page: SEO_META_PAGES.LANDING_START_CLOTHING_BRAND,
  },
  'start-a-skincare-line': {
    isBuilderIOPage: true,
    title: 'The best place to start and grow an skincare brand online.',
    description: getDefaultDescription('a skincare line'),
    path: '/l/start-a-skincare-line',
    data: START_A_SKINCARE_LINE,
    page: SEO_META_PAGES.LANDING_START_A_SKINCARE_LINE,
  },
  'start-a-athleisure-line': {
    isBuilderIOPage: true,
    title: 'The best place to start and grow an athleisure brand online.',
    description: getDefaultDescription('a athleisure line'),
    path: '/l/start-a-athleisure-line',
    data: START_A_ATHLEISURE_LINE,
    page: SEO_META_PAGES.LANDING_START_A_ATHLEISURE_LINE,
  },
  'start-a-jewelry-line': {
    isBuilderIOPage: true,
    title: 'The best place to start and grow an jewelry brand online.',
    description: getDefaultDescription('a jewelry line'),
    path: '/l/start-a-jewelry-line',
    data: START_A_JEWELRY_LINE,
    page: SEO_META_PAGES.LANDING_START_A_JEWELRY_LINE,
  },
  'start-a-candle-line': {
    isBuilderIOPage: true,
    title: 'The best place to start and grow an candle & fragrance brand online.',
    description: getDefaultDescription('a candle line'),
    path: '/l/start-a-candle-line',
    data: START_A_CANDLE_LINE,
    page: SEO_META_PAGES.LANDING_START_A_CANDLE_LINE,
  },
  'start-a-hair-products-line': {
    title: 'Start a hair products line in 3 steps',
    description: getDefaultDescription('a hair products line'),
    path: '/l/start-a-hair-products-line',
    data: START_A_HAIR_PRODUCTS_LINE,
    page: SEO_META_PAGES.LANDING_START_A_HAIR_PRODUCTS_LINE,
  },
  'start-a-leather-goods-line': {
    title: 'Start a leather goods line in 3 steps',
    description: getDefaultDescription('a leather goods line'),
    path: '/l/start-a-leather-goods-line',
    data: START_A_LEATHER_GOODS_LINE,
    page: SEO_META_PAGES.LANDING_START_A_LEATHER_GOODS_LINE,
  },
  'start-a-lip-gloss-line': {
    title: 'Start a lip gloss line in 3 steps',
    description: getDefaultDescription('a lip gloss line'),
    path: '/l/start-a-lip-gloss-line',
    data: START_A_LIP_GLOSS_LINE,
    page: SEO_META_PAGES.LANDING_START_A_LIP_GLOSS_LINE,
  },
  'start-a-makeup-line': {
    title: 'Start a makeup line in 3 steps',
    description: getDefaultDescription('a makeup line'),
    path: '/l/start-a-makeup-line',
    data: START_A_MAKEUP_LINE,
    page: SEO_META_PAGES.LANDING_START_A_MAKEUP_LINE,
  },
  'start-a-sunglasses-line': {
    title: 'Start a sunglasses line in 3 steps',
    description: getDefaultDescription('a sunglasses line'),
    path: '/l/start-a-sunglasses-line',
    data: START_A_SUNGLASSES_LINE,
    page: SEO_META_PAGES.LANDING_START_A_SUNGLASSES_LINE,
  },
  'start-an-athleisure-brand': {
    title: 'Start an athleisure or fitness brand in 3 steps',
    description: getDefaultDescription('an athleisure and fitness'),
    path: '/l/start-an-athleisure-brand',
    data: START_ATHLEISURE_FITNESS_BRAND,
    page: SEO_META_PAGES.LANDING_START_ATHLEISURE_BRAND,
  },
  'start-an-eyeshadow-line': {
    title: 'Start an eyeshadow line in 3 steps',
    description: getDefaultDescription('an eyeshadow line'),
    path: '/l/start-an-eyeshadow-line',
    data: START_AN_EYESHADOW_LINE,
    page: SEO_META_PAGES.LANDING_START_AN_EYESHADOW_LINE,
  },
};

export const pagePathsWithBuilderIO = Object.keys(pagePaths).filter((key) => pagePaths[key].isBuilderIOPage);

export const HOME_PAGE_BRAND_VIDEO_LIST: BrandVideoData[] = [
  {
    logoWidth: 121,
    logoHeight: 16,
    logoAlt: 'homecourt',
    logoSrc: `${STATIC_FILE_URL}/83a6f4a1fbf20f58e3bbd2b762ef14e9.png`,
    videoPoster: `${STATIC_FILE_URL}/2b2b84d9157cfeaead1bb192b835dfec.png`,
    videoSrc: `${STATIC_FILE_URL}/b28acb8bf7f30f39cbdc87da240be622.mp4`,
  },
  {
    logoWidth: 55,
    logoHeight: 16,
    logoAlt: 'finn',
    logoSrc: `${STATIC_FILE_URL}/d66c21adf676f537138dc8c8bfbbb00a.png`,
    videoPoster: `${STATIC_FILE_URL}/9dc4db3b815cf374e293a0308fbff028.png`,
    videoSrc: `${STATIC_FILE_URL}/decc73adc420f9ffb255be53736d0967.mp4`,
  },
  {
    logoWidth: 101,
    logoHeight: 16,
    logoAlt: 'houseplant',
    logoSrc: `${STATIC_FILE_URL}/b8e73610c431bca03a81c72eff19c394.png`,
    videoPoster: `${STATIC_FILE_URL}/cf4611e37b87b905a3f4bb55cc78079f.png`,
    videoSrc: `${STATIC_FILE_URL}/a3379cebbe811b3fd2c2b6402f82bfdb.mp4`,
  },
  {
    logoWidth: 68,
    logoHeight: 13,
    logoAlt: 'vacation',
    logoSrc: `${STATIC_FILE_URL}/0519cd92caee3c934d1fa3734e380d6c.png`,
    videoPoster: `${STATIC_FILE_URL}/6167c2e3ba24cc1cfdc43b875b57dc00.png`,
    videoSrc: `${STATIC_FILE_URL}/1c6f846c7edb98ed4d1105e348ec1dd0.mp4`,
  },
  {
    logoWidth: 67,
    logoHeight: 20,
    logoAlt: 'arrae',
    logoSrc: `${STATIC_FILE_URL}/0896cc7986b238044fab45052d44b9a2.png`,
    videoPoster: `${STATIC_FILE_URL}/5a91bde40a5a26c9c644e1a17da6750b.png`,
    videoSrc: `${STATIC_FILE_URL}/7b2df8cd56a3dfc286c47dee25f0e08e.mp4`,
  },
  {
    logoWidth: 73,
    logoHeight: 16,
    logoAlt: 'curie',
    logoSrc: `${STATIC_FILE_URL}/4daea92c8726199663fc80181784f9f1.png`,
    videoPoster: `${STATIC_FILE_URL}/8e6b932f9eebb2ec60490df2ee8bc64b.png`,
    videoSrc: `${STATIC_FILE_URL}/b54c1a440064bcc24ab4ff5ea42851e7.mp4`,
  },
  {
    logoWidth: 50,
    logoHeight: 24,
    logoAlt: 'bad-mouth',
    logoSrc: `${STATIC_FILE_URL}/93e183530089ebc01cb8004412abc564.png`,
    videoPoster: `${STATIC_FILE_URL}/fc16f61afa0d0dc86ba0c216293931f3.png`,
    videoSrc: `${STATIC_FILE_URL}/d3e921a54b61fbd60fa01fdaa35a2f26.mp4`,
  },
  {
    logoWidth: 119,
    logoHeight: 16,
    logoAlt: 'forvr-mood',
    logoSrc: `${STATIC_FILE_URL}/c57218ffdca6e621df0a11db3cdd4a5a.png`,
    videoPoster: `${STATIC_FILE_URL}/0d51c58b4e42c2fc9a60876cd400a05a.png`,
    videoSrc: `${STATIC_FILE_URL}/6b22104a182f763092a41ef8c73ab22a.mp4`,
  },
];
