import { SpotlightItemProps } from '../constants/pricing';
import { IMembershipFeature } from './subscription';

export enum LandingPageHeroType {
  COMPARISON = 'COMPARISON',
  SPOTLIGHT = 'SPOTLIGHT',
}

export interface LandingPageFeature {
  image: string;
  title: string;
  description: string;
  buttonText: string;
  dicountText?: string;
}

export interface HighlightCardProps {
  img: string;
  title: string;
  bgColor?: string;
}

export interface CompetitorComparison {
  pietra: {
    logo: string;
    items: string[];
  };
  competitor: {
    name: string;
    logo: string;
    items: string[];
  };
}

export interface LandingPageQuote {
  quote: string;
  author: string;
  link: string;
}

export interface LandingPageHero {
  type: LandingPageHeroType;
  showPietraLogo?: boolean;
  showBrandLogo?: boolean;
  showPlatformLogo?: boolean;
  title: string[];
  subtitle?: string;
  button?: {
    text: { text: string; style?: string }[];
    mobileText?: { text: string; style?: string }[];
  };
  quote?: {
    quote: string;
    author: string;
    link: string;
  };
  competitorComparisons?: CompetitorComparison[];
  spotlightItems?: SpotlightItemProps[];
  rating?: string;
}

export interface LandingPageData {
  hero: LandingPageHero;
  reviewsTitle: string;
  footer: {
    title: string;
    description: string;
  };
  features: {
    title: string;
    features: LandingPageFeature[];
  };
}

export interface ExistingBrandOffering {
  title: string;
  valueProps: string[];
}

export interface ExistingBrandPageData {
  hero: {
    title: string[];
    subtitle: string;
    offerings: ExistingBrandOffering[];
    spotlightItems: SpotlightItemProps[];
  };
  membershipFeatures: IMembershipFeature[];
  highlightCards?: SpotlightItemProps[];
}

export interface NewBrandsHeroData {
  bgColor: string;
  preTitleImg?: string;
  title: string;
  titleColor: string;
  subtitle: string;
  spotlightItems: SpotlightItemProps[];
  btnText: string;
  showHeroItem?: boolean;
}

export interface NewBrandsPageData {
  hero: NewBrandsHeroData;
  sections: IMembershipFeature[];
  sectionsTitle: string;
  highlightCards: {
    title: string;
    cards: HighlightCardProps[];
  };
}

export interface Callout {
  text: string;
}

export interface BrandVideoData {
  logoWidth?: number;
  logoHeight?: number;
  logoAlt?: string;
  logoSrc?: string;
  videoPoster?: string;
  videoSrc?: string;
}
